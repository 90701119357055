import React, { useState } from 'react';
import { Card } from '../Card';
import { useAppContext } from '../../contexts/AppContext';
import styled from '@emotion/styled';
import { Box, useMediaQuery } from '@mui/material';
import { Title } from '../Title';
import { Card as MuiCard } from '@mui/material';
import { CustomButton } from '../CustomButton';
import { useTheme } from '@mui/material/styles';
import { Link } from '../Link';

export interface CollectionCommonCProps {
  template: string;
  items: any;
  component?: any;
  name?: any
  type?: string;
  slug?: any;
}

const ProductSection = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  gap: theme.spacing(4),
  padding: 0,
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: 'repeat(1, 1fr)',
  },
}));

const MoreButton = styled('div')(({ theme }) => ({
  gridColumn: '1 / -1',
  padding: '10px',
  border: 'none',
  borderRadius: '5px',
  cursor: 'pointer',
  textAlign: 'center',
  marginTop: '10px',
  fontSize: theme.spacing(3),
}));

const CardWrapper = styled(MuiCard)(({ theme }: any) => ({
  padding: theme.spacing(3)
}));

const CardStyled = styled(Card)(({ theme, template, hoverEffect, cardBorder }: any) => ({
  width: '100%',
  background: "none",
  boxShadow: "none",
  borderRadius: 0,
  border: 0,
  borderBottom: cardBorder || `${theme.theme_component_card_content_border}px` || 'none',
  borderStyle: theme.theme_component_card_content_border ? 'solid' : 'none',
  borderColor: theme.theme_component_card_content_border ? theme.theme_component_card_content_border_color : 'none',
  transition: `transform ${theme.transitions.duration.shortest}ms ${theme.transitions.easing.easeInOut}`,
  paddingBottom: theme.spacing(1),
  '&:hover': hoverEffect && {
    transform: 'scale3d(1.05, 1.05, 1)',
  },
  '&:last-child': {
    borderBottom: 'none',
  },
  '.inner-card': {
    alignItems: "flex-start !important",
    flexDirection: "row",
    display: "inline-flex",
    '& > div:first-child': {
      display: "inline-flex !important",
    },
  }
}));

const BoxStyled = styled(Box)(({ theme }: any) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  overflow: "hidden"
}));

export const ListViewA = (props: CollectionCommonCProps) => {
  const { items = [], template, component } = props;
  const theme = useTheme();
  const [showAll, setShowAll] = useState(false);
  const appContext = useAppContext();
  const { assetUrl } = appContext.useConfig();
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));

  const visibleItems = showAll ? items : items.slice(0, 8);
  const half = Math.ceil(visibleItems.length / 2);
  const leftItems = visibleItems.slice(0, half);
  const rightItems = visibleItems.slice(half);

  console.log("props", props)

  return (
    <Box mb={5}>
      {!props?.component?.hideTitle && (
        <Link
          href={`/${props.type}/${props.slug}`}
          title={props.name || undefined}
          underline="none"
        >
          <Title variant={'h2'}>
            {props.name}
          </Title>
        </Link>
      )}
      <CardWrapper>
        <ProductSection>
          <BoxStyled sx={{ gap: 1 }}>
            {leftItems.map((game: any, index: number) => (
              <CardStyled
                key={`${game.id}_${index}`}
                href={`/game/${game.slug}`}
                src={`${assetUrl}${
                  [1, 4, 7].includes(index)
                    ? game.images.cover4x3 || game.images.cover
                    : game.images.cover16x9 || game.images.cover
                }/`}
                alt={game.name}
                assetsx={{ display: 'flex', width: "80px", flex: "none" }}
                innerFlex="row"
                title={game.name || undefined}
                description={game.description}
                template={template}
                width={80}
                height={80}
                layout="responsive"
                hideDescription={component?.hideDescription}
              />
            ))}
          </BoxStyled>

          {!mdDown || showAll ? (
            <BoxStyled>
              {rightItems.map((game: any, index: number) => (
                <CardStyled
                  key={`${game.id}_${index + 4}`}
                  href={`/game/${game.slug}`}
                  src={`${assetUrl}${
                    [1, 4, 7].includes(index)
                      ? game.images.cover4x3 || game.images.cover
                      : game.images.cover16x9 || game.images.cover
                  }/`}
                  alt={game.name}
                  assetsx={{ display: 'flex', width: "80px", flex: "none" }}
                  innerFlex="row"
                  title={game.name || undefined}
                  description={game.description}
                  template={template}
                  width={80}
                  height={80}
                  layout="responsive"
                  hideDescription={component?.hideDescription}
                />
              ))}
            </BoxStyled>
          ) : null}
        </ProductSection>

        {!showAll && items.length > 8 && (
          <MoreButton>
            <CustomButton color="primary" variant="outlined" onClick={() => setShowAll(true)}>
              More Games
            </CustomButton>
          </MoreButton>
        )}
      </CardWrapper>
    </Box>
  );
};
